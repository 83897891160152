import { Box, Divider, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { InformationsProps } from "../../types/InformationsTypes";
import { CpfMask, FormatMoneyDynamic } from "../../utils/masks";
import { useEffect, useState } from "react";

export function RequestedSummary({
  setOpenSummary,
  holderName,
  holderCpf,
  holderPhone,
  holderEmail,
  holderAddress,
  holderCep,
  dependents,
  plan,
  paymentType,
  paymentDate,
  total,
  value,
  planNameSelected
}: InformationsProps) {
  const classes = useStyles();
  const today = new Date();
  const day = today.getDate().toString().padStart(2, '0');
  const [billDueDate, setBillDueDate] = useState(localStorage.getItem("selectedDaysBoleto") || "");
  
  useEffect(() => {
    const interval = setInterval(() => {
      const newValue = localStorage.getItem("selectedDaysBoleto") || "";
      if (newValue !== billDueDate) {
        setBillDueDate(newValue);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [billDueDate]);

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.title}>Resumo do pedido</Typography>
        <CloseIcon onClick={() => setOpenSummary(false)} />
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.containerInfoHolder}>
        <Box className={classes.containerRow}>
          <Typography className={classes.titleHolder}>Titular</Typography>
          <Box className={classes.containerIcon}>

          </Box>
        </Box>
        <Typography className={classes.infoHolder}>
          {holderName || "Não informado"} (CPF: {CpfMask(holderCpf) || "Não informado"})
        </Typography>
        <Box
          className={classes.containerRow}
          style={{ justifyContent: "normal" }}
        >
          <Typography className={classes.infoHolder}>
            {holderPhone || "Não informado"}
          </Typography>
          <Typography className={classes.infoHolder}>
            {holderEmail || "Não informado"}
          </Typography>
        </Box>
        <Typography className={classes.infoHolder}>
          {holderAddress || "Não informado"}
        </Typography>
        <Typography className={classes.infoHolder}>
          CEP: {holderCep || "Não informado"}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.containerInfoHolder}>
        <Box className={classes.containerRow}>
          <Typography className={classes.titleHolder}>
            Informações do plano
          </Typography>
          <Box className={classes.containerIcon}>

          </Box>
        </Box>
        <Typography className={classes.infoHolder}>
        {planNameSelected
            ? `${planNameSelected} (${FormatMoneyDynamic(value ?? "29.90")})`
            : "Não informado"}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.containerInfoHolder}>
        <Box className={classes.containerRow}>
          <Typography className={classes.titleHolder}>
            Forma de pagamento
          </Typography>
          <Box className={classes.containerIcon}>
          </Box>
        </Box>
        <Typography className={classes.infoHolder}>
          Pagamento: {paymentType === "boleto" ? "Parcelado em 12x" : "Mensal (recorrente)"}
        </Typography>
        <Typography className={classes.infoHolder}>
          Tipo de pagamento:{" "}
          {paymentType === "credit_card"
            ? "Cartão de crédito"
            : paymentType === "boleto"
            ? "Boleto Bancário"
            : paymentType === "cpfl"
            ? "Pagamento CPFL"
            : "Não informado"}
        </Typography>

        <Typography className={classes.infoHolder}>
          Data do lançamento: Mensal dia {day}
        </Typography>
        {paymentType === "boleto" && (
          <Typography className={classes.infoHolder}>
            Data do vencimento: Mensal dia {billDueDate}
          </Typography>
        )}
        <Typography className={classes.observation}>
          * Caso o cartão seja cancelado ou renovado, por favor entre em contato
          com a nossa equipe para atualização financeira, caso contrário seu
          plano será cancelado automaticamente
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box
        className={classes.containerRow}
        style={{ justifyContent: "space-between" }}
      >
        <Typography
          className={classes.titleHolder}
          style={{ fontSize: "20px" }}
        >
          Total:
        </Typography>
        <Typography
          className={classes.titleHolder}
          style={{ fontSize: "35px" }}
        >
          {FormatMoneyDynamic(value ?? '0,00')}
        </Typography>
      </Box>
    </Box>
  );
}
